import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Чарівний Вікенд - Ваш ідеальний спа відпочинок
			</title>
			<meta name={"description"} content={"Запрошуємо вас на спа вікенди Чарівний Вікенд - Ваш ідеальний спа відпочинок. Насолоджуйтесь релаксом та відновленням у найкращих умовах."} />
			<meta property={"og:title"} content={"Головна | Чарівний Вікенд - Ваш ідеальний спа відпочинок"} />
			<meta property={"og:description"} content={"Запрошуємо вас на спа вікенди Чарівний Вікенд - Ваш ідеальний спа відпочинок. Насолоджуйтесь релаксом та відновленням у найкращих умовах."} />
			<link rel={"shortcut icon"} href={"https://infinitehoriz.com/img/4336570.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://infinitehoriz.com/img/4336570.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://infinitehoriz.com/img/4336570.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://infinitehoriz.com/img/4336570.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://infinitehoriz.com/img/4336570.png"} />
			<meta name={"msapplication-TileImage"} content={"https://infinitehoriz.com/img/4336570.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="160px 0 170px 0" background="linear-gradient(180deg,rgba(5, 7, 33, 0.1) 0%,rgba(5, 7, 33, 0.1) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://infinitehoriz.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-8">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="#111"
					lg-padding="0px 0 0px 0"
				>
					Чарівний Вікенд - Ваш ідеальний спа відпочинок!
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#d4dae0"
					padding="0px 200px 0px 200px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Ласкаво просимо до Чарівний Вікенд – місця, де мрії про ідеальний відпочинок стають реальністю. Наші спа-процедури ідеально підходять для тих, хто шукає спокій і гармонію. Відвідайте наш курорт, щоб поновити свої сили і забути про щоденні турботи. Ми пропонуємо широкий спектр послуг, які зроблять ваш вікенд незабутнім.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="--color-orange"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border="2px solid #111"
						color="#111"
						hover-background="rgba(63, 36, 216, 0)"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
					>
						Зв'яжіться з нами
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="#170f06">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
				Наші послуги
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
				У Чарівний Вікенд ви знайдете різноманіття спа-процедур, включаючи масажі, аромотерапію, гідротерапію, а також спеціальні програми для релаксації. Наші професіонали подбають про ваше здоров'я і гарний настрій, використовуючи тільки натуральні продукти і найсучасніші методи.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" background="#170f06" quarkly-title="Description-11">
			<Override
				slot="SectionContent"
				flex-wrap="wrap"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
			/>
			<Box border-color="--color-light" width="100%" border-width="0px 0px 1px 0px" border-style="solid" />
			<Box width="100%" margin="20px 0px 0px 0px">
				<Text
					margin="0px 0px 0px 0px"
					font="--headline2"
					color="--light"
					letter-spacing="-.05em"
					lg-font="normal 400 70px/1.1em &quot;Inter&quot;, sans-serif"
					sm-font="normal 400 40px/1.1em &quot;Inter&quot;, sans-serif"
				>
					Переваги нашого курорту
				</Text>
			</Box>
			<Box
				margin="30px 0px 30px 0px"
				lg-margin="56px 0px 30px 0px"
				md-display="none"
				width="33.333%"
				padding="20px 20px 20px 20px"
			/>
			<Box
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="30px 0px 30px 0px"
				lg-margin="56px 0px 30px 0px"
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
				Приватні номери з чудовим видом
				</Text>
			</Box>
			<Box
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="30px 0px 30px 0px"
				lg-margin="56px 0px 30px 0px"
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
				Високоякісні спа-послуги
				</Text>
			</Box>
			<Box
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="30px 0px 50px 0px"
				lg-margin="56px 0px 30px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
				Індивідуальний підхід до кожного клієнта
				</Text>
			</Box>
			<Box
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="30px 0px 50px 0px"
				lg-margin="56px 0px 30px 0px"
			>
				<Text color="--light" margin="0px 0px 0px 0px" font="--headline3">
				Різноманітні розважальні програми
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fe240cd81ee001f7dd8c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});